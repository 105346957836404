.accContainer {
  align-items: center;
}

.displayContainer {
  background-color: #fff;
  /* background-color: red; */
  /* border-radius: 10px; */
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 5px;
  padding-top: 5px;
  /* margin-left: 10px;
   margin-right: 10px; */
  align-items: center;
  height: 55px;
  display: flex;
  box-shadow: 0px 0px 8.107px 0px rgba(0, 0, 0, 0.25);
}

.imgTopStyles {
  /* height: 200px;
    width: 100%; */
  /* border-radius: 10px; */
  height: 2.5rem;
  margin-top: 7px;
  margin-right: 25px;
  margin-left: 20px;
}

.userGreetings {
  margin-left: auto;
  margin-right: 20px;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
}

.infoBottomImage {
  display: flex;
  padding: 0px 0px 5px 5px;
}

.textInfoBottomImage {
  color: #5f5c70;
  margin-right: 20px;
  margin-left: 20px;
}

.textInfoBottomImageRight {
  color: #5f5c70;
  margin-left: auto;
  margin-right: 20px;
}

.userGreetingsMessage {
  display: flex;
}

.userGreetingIcon {
  margin-right: 5px;
  color: #535354;
}

.MuiCircularProgress-colorPrimary {
  color: #4E253A !important;
}

.MuiCircularProgress-root {
  left: 43%;
  position: absolute;
  top: 44vh;
}

.selectedMLDiv {
  display: flex;
  flex-direction: row;
  width: 97%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  justify-content: space-between;
  margin: 20px;
  background-color: #FFF2F9;
  padding: 5px;
  box-shadow: 0px 1px 2.4px 1px rgba(0, 0, 0, 0.10);
}

.hubIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  border-radius: 50px;
  padding: 4px;
}

.MLIdDiv {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-left: 6px;
  padding-right: 10px;
  font-size: 20px;
  font-weight: 700;
  font-family: "GTWalsheimProRegular";
  color: #ffffff;
  background-color: #4E253A;
  border: 1px solid #fff;
}

.unpairButton {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 6px;
  font-size: 16px;
  font-weight: 700;
  font-family: "GTWalsheimProRegular";
  background-color: #F14C00;
  color: #ffffff;
  border: 1px solid #fff;
}

.unpairButton:hover {
  cursor: pointer;
  background-color: #AA346F;
}

.modal {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  position: relative;
  /* margin-left: 10%;
          margin-right: 90%;
          top: 50; */
}

.paperUnassignML {
  background-color: #fff;
  outline: none;
  border-radius: 20px;
  align-items: center;
  width: 400px;
  /* height: 350px; */
  overflow-x: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 340px;
  width: 350px;
}

.menuTitle {
  background-color: #FFF1F8;
  display: flex;
  /* border-bottom: 1px solid gray; */
  text-align: center;
  align-items: center;
  font-size: 20px;
  font-family: "GTWalsheimProRegular";
  font-weight: bold;
  justify-content: center;
  padding: 10px;
  color: #4E253A;
}

.titleText {
  font-style: "GTWalsheimProRegular";
  font-weight: bold;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 28px;
  padding: 10px;
  color: #4E253A;
}

.allPatientsHeader {
  width: 100%;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  background-color: #FFEEEA;
  margin-bottom: 20px;
}

.projectNameDiv {
  font-weight: bold;
  font-size: 20px;
  font-family: "GTWalsheimProRegular";
  color: #DF5927;
}

.locationDiv {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: "GTWalsheimProRegular";
  color: #4E253A;
  margin-right: 25px;
}

.locIconDiv {
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50%;
}

.userInfoDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #FFEEEA;
  border-radius: 50px;
}

.userNameDiv {
  padding-left: 10px;
  padding-right: 20px;
  font-weight: bold;
  font-size: 16px;
  font-family: "GTWalsheimProRegular";
  /* color: #fe6b59; */
  color: #DF5927;
}

.userPicDiv {
  /* border: 3px solid #fe6b59; */
  border: 3px solid #DF5927;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.avatar {
  height: 20px;
  width: 20px;
}

.avatar2 {
  height: 24px !important;
  width: 24px !important;
  border: 1.5px solid #7C596A;
}

.leftArrow {
  height: 24px !important;
  width: 24px !important;
  color: #FFFFFF;
}

/* .leftArrow:hover {
  color: #87668B;
} */

.userInfoDiv {
  display: flex;
  flex-direction: row !important;
  column-gap: 10px !important;
  border-radius: 50px;
  border: 1.5px solid #7C596A;
  padding-left: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 5px;
  background-color: #FFFBFD;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 140px;
  align-items: center;
  justify-content: left;
  font-size: 16px;
  font-family: "GTWalsheimProRegular";
}

.pageHeader {
  margin-left: 20px;
  display: flex !important;
  flex-direction: row;
  column-gap: 10px;
  font-family: 'GTWalsheimProRegular';
  font-size: 20px;
  font-weight: 900;
  align-items: center;
  justify-content: center;
  color: #4E253A;
}

.backButtonDiv {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  background-color: #E9DBEB;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  border: 1.5px solid #7C596A;
  padding-left: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 10px;
  align-items: center;
  justify-content: left;
  font-size: 16px;
  font-family: "GTWalsheimProRegular";
  color: #87668B;
}

.backButtonDiv:hover {
  cursor: pointer;
  color: #FFFFFF;
  background-color: #87668B;
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #87668B;
  border: 1.5px solid #FFFFFF;
  border-radius: 50px;
}

.mlImg {
  width: 90px;
  height: 120px;
}

.mouthLabImgDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitButton {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 40%;
  border-radius: 30px;
  padding: 10px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 12px;
  background-color: #AA346F;
  border: 1px solid #AA346F;
  color: white;
}

.submitButton:hover {
  background-color: #F14C00;
  color: white;
  border: 1px solid #F14C00;
}

.cancelButton {
  margin-top: 10px;
  margin-right: 10px;
  min-width: 40%;
  font-weight: 600;
  border-radius: 30px;
  padding: 10px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 12px;
  color: #AA346F;
  background-color: #FFFFFF;
  border: 1px solid #AA346F;
}

.cancelButton:hover {
  color: #AA346F;
  background-color: #FFE0F0;
  border: 1px solid #FFE0F0;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-left: 10px;
}

.userQuestionMessage {
  font-family: "GTWalsheimProRegular";
  color: #4E253A;
  font-weight: 600;
  font-size: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 15px;
  text-wrap: wrap;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
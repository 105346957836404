.containerDiv {
  /* width: 100%; */
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 5px;
  /* padding: 10px; */
  background-color: #ffffff;
  /* background-image: linear-gradient(to right, #5B5687, #E7E6ED); */
  /* background-image: linear-gradient(to right, #D4D3E1, #EEEEF4); */
  /* background-image: linear-gradient(to right, #C8C7D7, #EEEEF4); */
  border-radius: 10px;
}

.datePickerDiv {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #faeaea;
  text-align: left;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  vertical-align: middle;
}

.datePickerDiv svg {
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: #FFFFFF;
  background-color: #4E253A;
  padding: 8px;
  border-radius: 30px;
}

.dateInput {
  min-width: 220px;
  max-width: 600px;
  background-color: #faeaea;
  text-align: center;
  color: #4E253A;
  font-weight: 200;
  border-style: none;
  border-radius: 10px;
  outline: none;
  caret-color: #4E253A;
  font-family: GTWalsheimProRegular;
  font-size: 17px;
  padding: 10px 10px 6px 10px;
}

.exportButton {
  vertical-align: middle !important;
  justify-content: center !important;
  /* display:flex !important; */
  flex-direction: row !important;
  text-align: center !important;
  align-items: center !important;
  color: #fff !important;
  font-size: 13px !important;
  border-radius: 8px !important;
  background-color: #7C596A !important;
  font-family: GTWalsheimProRegular !important;
  text-transform: capitalize !important;
  width: 85px !important;
  margin: 4px 6px 2px 5px !important;
  padding: 3px 0px !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.exportButton svg {
  width: 23px;
  height: 23px;
  padding: 2px 0px 2px 2px;
  border-radius: 3px;
  background-color: inherit;
}

.exportButton:hover {
  background-color: #4E253A !important;
}

.datePickerWrapper {
  /* margin-left: 190px; */
  border-radius: 10px;
  margin-right: auto;
  width: fit-content;
  margin-right: auto;
  overflow: auto;
  visibility: visible;
  color: #4E253A;
  z-index: 2;
  position: absolute;
  border: 2px solid #4E253A;
}

.notesEditor {
  display: block;
  width: 100%;
  padding-right: 10px;
  border-radius: 22.156px;
  border: 1.846px solid #D6A0BA;
  font-size: 16px;
  align-items: start;
  justify-content: left;
  margin-top: 13px;
  margin-bottom: 10px;
  margin-left: 20px;
  padding-left: 20px !important;
  font-family: GTWalsheimProRegular !important;
  background-color: #FCF9FA;
  box-shadow: 0px 0px 4px #ccc;
  overflow-wrap: break-word;
  max-height: 10vh;
  overflow-y: scroll;
}

.notesEditor::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari and Opera */
}

.notesEditor {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.notesDisplayer {
  position: relative;
  min-width: 95%;
  font-size: 16px;
  align-items: start;
  justify-content: left;
  margin-top: 5px;
  margin-left: 10px;
  padding: 10px !important;
  display: inline-block;
  overflow: hidden;
  font-family: GTWalsheimProRegular !important;
  background-color: #FFEBF8;
}

.newNotesWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.dummyWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.addNewNoteDiv {
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  column-gap: 0px;
  position: relative;
  width: 100%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px -6px 6px 0px;
  margin-left: 0px;
}

.tagNameAdherence {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #FEBADB;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagAdherence1 {
  font-size: 14px;
  background-color: #FEBADB;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FEBADB;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagAdherence {
  font-size: 14px;
  background-color: #FEBADB;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FEBADB;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 6px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.tagNameMedication {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #C6F1DD;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagMedication {
  font-size: 14px;
  background-color: #C6F1DD;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #C6F1DD;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 6px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagMedication1 {
  font-size: 14px;
  background-color: #C6F1DD;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #C6F1DD;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.tagNameVitals {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #FFCECE;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagVitals {
  font-size: 14px;
  background-color: #FFCECE;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FFCECE;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 6px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagVitals1 {
  font-size: 14px;
  background-color: #FFCECE;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FFCECE;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.tagNameDevice {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #FFE1F0;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagDevice {
  font-size: 14px;
  background-color: #FFE1F0;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FFE1F0;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 6px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagDevice1 {
  font-size: 14px;
  background-color: #FFE1F0;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #FFE1F0;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.tagNameOthers {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #F3D8F6;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  color: #172228;
  font-family: 'GTWalsheimProRegular' !important;
}

.selectedTagOthers {
  font-size: 14px;
  background-color: #F3D8F6;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #F3D8F6;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 6px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.selectedTagOthers1 {
  font-size: 14px;
  background-color: #F3D8F6;
  color: #172228;
  border-radius: 50px;
  border: 1.5px solid #F3D8F6;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 4px;
  margin-right: 3px;
  font-family: 'GTWalsheimProRegular' !important;
  box-shadow: 0px 4.01px 4.01px 0px rgba(0, 0, 0, 0.15);
}

.allNotesDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
}

.selectTagsDiv {
  margin-left: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.rootDiv {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

.noteHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  border: 1px solid #FFCFEF;
  padding-top: 3px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #FFFFFF;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.createdDiv {
  display: flex;
  flex-direction: row;
  column-gap: 40px;
  align-items: center;
  justify-content: center;
}

.noteTags {
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  justify-content: left;
}

.noteTextArea {
  /*border: 1px solid #cccccc;*/
  box-shadow: 0px 0px 4px #ccc;
  border-radius: 10px;
  background-color: #FFEBF8;
  padding-bottom: 1px;
  padding-left: 0px;
  border: 1px solid #FFCFEF;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.selectedTagName {
  font-size: 11px;
  background-color: #7C596A;
  color: #ffffff;
  border-radius: 50px;
  border: 1px solid #7C596A;
  padding-left: 4px;
  padding-right: 4px;
  max-height: 14px;
}

.noteMeasurementTime {
  font-size: 13px;
  font-family: GTWalsheimProRegular !important;
  margin-left: 5px;
  margin-bottom: 5px !important;
  background-color: #cccccc;
  width: fit-content;
  border-radius: 50px;
  padding-left: 5px;
  padding-right: 5px;
  height: 18px;
  margin-top: 10px;
}

.noteBlock {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-right: 10px;
  width: 90%;
}

.showNotesDiv {
  overflow-y: scroll;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  overflow-x: hidden;
  height: 30vh;
  width: 100%;
}

.addNoteButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
}

.pinButton {
  height: 18px !important;
  width: 18px !important;
  color: #4E253A;
  font-size: 9px;
}

.createdBy {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 17px !important;
  font-family: 'GTWalsheimProRegular' !important;
  font-weight: bold;
  color: #4E253A;
}

.createdTime {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  font-weight: normal !important;
  color: #4E253A;
  float: right !important;
  background-color: #FEE7F7;
  filter: drop-shadow(0px 3.078px 3.078px rgba(0, 0, 0, 0.25));
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 50px;
  height: fit-content;
  margin-top: -4px;
}

.allNotesHeader {
  font-size: 25px !important;
  font-family: GTWalsheimProRegular !important;
  font-weight: bold;
  /* margin-bottom: -25px;
    margin-top: -20px; */
  margin-left: 25px;
  margin-bottom: 10px;
}

.searchBar {
  position: relative;
  width: 90%;
  height: 40px;
  padding-right: 10px;
  border-radius: 50px;
  font-size: 16px !important;
  box-shadow: 0px 0px 4px #ccc;
  align-items: center;
  justify-content: left;
  margin-top: 20px;
  margin-left: 25px;
  margin-right: 0px;
  margin-bottom: 10px;
  background-color: #FFF6FB;
}

.searchIcon {
  height: 20px !important;
  width: 20px !important;
  float: left;
  color: #4E253A;
  margin-right: 5px;
}

.circularProgress {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-items: center;
}

.characterLimitDiv {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100px;
  align-items: center;
  font-size: 12px;
  color: rgb(185, 185, 185);
  font-family: "GTWalsheimProRegular";
  padding-left: 10px;
  margin-top: -2px;
}

.newDumDiv {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  text-align: left;
  justify-content: left;
  align-items: start;
  width: fit-content;
  margin-right: -40px;
  padding-top: 10px;
}

.characterCountRed {
  color: red !important;
  width: fit-content;
}

.applyButtonDiv {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: right;
  background-color: #FFFFFF;
}

.applyButton {
  vertical-align: middle !important;
  justify-content: center !important;
  /* display:flex !important; */
  flex-direction: row !important;
  text-align: center !important;
  align-items: center !important;
  color: #fff !important;
  font-size: 15px !important;
  border-radius: 8px !important;
  background-color: #7C596A !important;
  font-family: GTWalsheimProRegular !important;
  text-transform: capitalize !important;
  width: 85px !important;
  margin: 4px 6px 2px 5px !important;
  padding: 3px 0px !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.applyButton:hover {
  background-color: #4E253A !important;
  cursor: pointer;
}

.circularProgressDiv {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.submitNotesButton {
  display: flex;
  width: 87px;
  height: 5.4vh;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 0px 0px 5px 0px;
  background: #87668B;
  color: #FFFFFF;
  margin-left: 10px;
}

.submitNotesButton:hover {
  background-color: #4E253A;
  cursor: pointer;
}

.submitNotesButtonDisabled {
  display: flex;
  width: 87px;
  height: 5.4vh;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 0px 0px 5px 0px;
  background: #DFD4E1;
  color: #FFFFFF;
  margin-left: 10px;
}